import React, { useState } from 'react';
import {Divider, List, ListItem, Paper, Typography, Switch, FormControlLabel} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const formatCode = (code) => {
    const withSpaces = code.replace(/_/g, ' ');
    return withSpaces
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
};

const formatSectionName = (name) => {
    if (!name) return '';
    return name
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
};

const IssuesList = ({ 
    issues, 
    label = 'Issues', 
    showAll: initialShowAll = false, 
    ignoreSections = [],
    enableStatusToggle = false 
}) => {
    const [showAll, setShowAll] = useState(enableStatusToggle ? initialShowAll : false);

    const renderIssues = (issuesObject, showAll) => {
        const issueItems = [];

        const filterIssues = (issues) => {
            return showAll ? issues : issues.filter(issue => issue.status === 'error');
        };

        const processIssues = (issues, sectionName = '') => {
            if (sectionName && ignoreSections.some(pattern => {
                if (pattern.endsWith('*')) {
                    const prefix = pattern.slice(0, -1);
                    return sectionName.toLowerCase().startsWith(prefix.toLowerCase());
                }
                return sectionName.toLowerCase() === pattern.toLowerCase();
            })) {
                return;
            }

            const filteredIssues = filterIssues(Object.values(issues.issues_by_code));
            if (filteredIssues.length > 0) {
                issueItems.push(
                    <React.Fragment key={sectionName}>
                        {sectionName && (
                            <Typography 
                                variant='subtitle1' 
                                sx={{
                                    color: 'text.secondary',
                                    fontSize: '0.95rem',
                                    letterSpacing: '0.5px',
                                    paddingLeft: '12px',
                                    borderLeft: '3px solid',
                                    borderColor: 'divider',
                                    marginTop: 2,
                                    marginBottom: 1
                                }}
                            >
                                {formatSectionName(sectionName)}
                            </Typography>
                        )}
                        {filteredIssues.map((issue, index) => (
                            <div key={index}>
                                <ListItem sx={{ py: 0.5 }}>
                                    <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                                        <div style={{ marginRight: '8px', marginTop: '2px' }}>
                                            {issue.status === 'error' ? (
                                                <ErrorOutlineIcon color="error" sx={{ fontSize: '1.2rem' }} />
                                            ) : (
                                                <CheckCircleOutlineIcon color="success" sx={{ fontSize: '1.2rem' }} />
                                            )}
                                        </div>
                                        <div>
                                            <Typography 
                                                variant='body1' 
                                                sx={{ 
                                                    fontSize: '0.9rem',
                                                    fontWeight: 500,
                                                    lineHeight: 1.2,
                                                    mb: 0.5
                                                }}
                                            >
                                                {issue.msg || formatCode(issue.code)}
                                            </Typography>
                                            <Typography 
                                                variant='body2' 
                                                color='textSecondary'
                                                sx={{ 
                                                    fontSize: '0.8rem',
                                                    lineHeight: 1.2
                                                }}
                                            >
                                                {issue.readable}
                                            </Typography>
                                        </div>
                                    </div>
                                </ListItem>
                                {index !== filteredIssues.length - 1 && <Divider />}
                            </div>
                        ))}
                    </React.Fragment>
                );
            }

            if (issues.sections) {
                Object.entries(issues.sections).forEach(([subSectionName, subSectionIssues]) => {
                    processIssues(subSectionIssues, subSectionName);
                });
            }
        };

        processIssues(issuesObject);

        return issueItems;
    };

    if( !issues ){
        return <></>;
    }

    if (!issues.is_valid || showAll) {
        return <Paper elevation={3} style={{padding: '16px', maxWidth: '600px', margin: '16px auto'}}>
            <div style={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                marginBottom: 1
            }}>
                <Typography 
                    variant='subtitle1' 
                    sx={{
                        color: 'text.secondary',
                        fontSize: '1rem',
                        fontWeight: 500
                    }}
                >
                    {label}
                </Typography>
                {enableStatusToggle && (
                    <FormControlLabel
                        control={
                            <Switch 
                                size="small"
                                checked={showAll}
                                onChange={(e) => setShowAll(e.target.checked)}
                            />
                        }
                        label={
                            <Typography 
                                variant="body2" 
                                sx={{ 
                                    fontSize: '0.8rem',
                                    color: 'text.secondary'
                                }}
                            >
                                include passing checks
                            </Typography>
                        }
                        sx={{ margin: 0 }}
                    />
                )}
            </div>
            <List sx={{ py: 0 }}>
                {renderIssues(issues, showAll)}
            </List>
        </Paper>;
    }
};

export default IssuesList;

