import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, Typography, Box, FormHelperText } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';




const KratosFlowUIForm = ({ initialFormUI, onSuccess, onError }) => {
    const { register, handleSubmit, control, setValue, getValues, unregister } = useForm();
    const [formData, setFormData] = useState(initialFormUI);

    // React-query mutation for form submission
    const mutation = useMutation(
        (data) => axios.post(formData.action, data),
        {
            onSuccess: (response) => {
                console.log('Form submitted successfully:', response);
                if (response.data.ui) {
                    setFormData(response.data.ui); // Update form data based on response
                }

                if (onSuccess) {
                    onSuccess(response); // Call the onSuccess prop if provided
                }
            },
            onError: (error) => {
                console.error('Form submission failed:', error);
                if (error.response?.data?.error?.id) {
                    switch (error.response.data.error.id) {
                    case 'browser_location_change_required':
                        window.location.href = error.response.data.redirect_browser_to;
                        break;
                    }
                } else if (error.response?.data?.ui) {
                    setFormData(error.response.data.ui); // Handle generic error with UI update
                }
                if (onError) onError(error);
            },
        }
    );

    // Handle form submission and include only the clicked submit button
    const onSubmit = (data, event) => {
        const submitter = event.nativeEvent.submitter; // Get the submit button that was clicked

        // Check if the submitter exists (i.e., a submit button was clicked)
        if (submitter) {
            console.log('submitter', submitter);
            const submitterName = submitter.name;
            const submitterValue = submitter.value;

            // Remove other submit button values from the payload
            formData.nodes
                .filter((node) => node.attributes.type === 'submit' && node.attributes.name !== submitterName)
                .forEach((node) => unregister(node.attributes.name));

            console.log('getValues', getValues());
            console.log('data', data );
            // Ensure the clicked submit button's value is included
            setValue(submitterName, submitterValue);
        }

        // Trigger mutation on form submit
        mutation.mutate({ ...getValues() });
    };

    // Group nodes by their group attribute
    const groupNodes = () => {
        return formData.nodes.reduce((groups, node) => {
            const group = node.group || 'default';
            if (!groups[group]) {
                groups[group] = [];
            }
            groups[group].push(node);
            return groups;
        }, {});
    };

    const groupedNodes = groupNodes();

    // Render the shared inputs (from the 'default' group)
    const renderDefaultInputs = () => {
        const defaultNodes = groupedNodes['default'] || [];
        return defaultNodes.map((node, index) => renderNode(node, `default_${index}`));
    };

    // Render the messages for an input
    const renderMessages = (messages) => {
        return messages.map((message) => {
            switch (message.type) {
            case 'error':
                return (
                    <FormHelperText key={message.id} error>
                        {message.text}
                    </FormHelperText>
                );
            case 'info':
                return (
                    <Typography key={message.id} variant='body2' color='textSecondary'>
                        {message.text}
                    </Typography>
                );
            default:
                return null;
            }
        });
    };

    const renderNode = (node, index) => {
        const { name, type, value, required, pattern, disabled, autocomplete } = node.attributes;
        const label = node.meta?.label?.text || name;

        const uniqueKey = `${name}_${index}`;

        // Determine the type of input field to render
        switch (type) {
        case 'hidden':
            // Register hidden fields so they are included in form submission
            return <input key={uniqueKey} type='hidden' {...register(name)} defaultValue={value} />;
        case 'submit':
            // Use Material-UI Button as submit
            return (
                <Button
                    key={uniqueKey}
                    variant='contained'
                    type='submit'
                    disabled={disabled || mutation.isLoading}
                    value={value}
                    name={name}
                >
                    {label}
                </Button>
            );
        case 'text':
        case 'email':
        case 'password': // Adding support for the password input type
            return (
                <Box key={uniqueKey} mb={2}>
                    <Controller
                        name={name}
                        control={control}
                        defaultValue={value || ''}
                        rules={{ required, pattern: pattern ? new RegExp(pattern) : undefined }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label={label}
                                type={type} // Can now be 'password', 'text', or 'email'
                                required={required}
                                disabled={disabled}
                                autoComplete={autocomplete} // Support for autocomplete attribute
                                fullWidth
                                variant='outlined'
                                margin='normal'
                                error={!!(node.messages && node.messages.some((msg) => msg.type === 'error'))}
                            />
                        )}
                    />
                    {/* Render messages associated with the field */}
                    {node.messages && renderMessages(node.messages)}
                </Box>
            );
        default:
            return null;
        }
    };

    const renderGroupForm = (group, nodes) => {
        return (
            <form
                key={group}
                onSubmit={handleSubmit((data, event) => onSubmit(data, event))}
            >
                {/* Render shared default inputs */}
                {renderDefaultInputs()}

                {/* Render inputs specific to this group */}
                {nodes.map((node, index) => renderNode(node, `${group}_${index}`))}

            </form>
        );
    };

    return (
        <>
            {formData.messages && formData.messages.map((message) => (
                <Typography key={message.id} variant='body2' color='textSecondary'>
                    {message.text}
                </Typography>
            ))}

            {Object.entries(groupedNodes).map(([group, nodes]) => (
                group !== 'default' && (
                    <Box key={group} mb={3}>
                        <Typography variant='h6' gutterBottom>{group.charAt(0).toUpperCase() + group.slice(1)}</Typography>
                        {renderGroupForm(group, nodes)}
                    </Box>
                )
            ))}
        </>
    );
};

export default KratosFlowUIForm;
